import React, { FC, useCallback, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { TextField, InputAdornment, Link, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { toAbsoluteUrl } from 'src/app/common/utils';
import ApplicationItem from './components/ApplicationItem';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { TokenInfo } from '../../../Auth/types/auth-types';
import { useDataProvider, useUpdateEffect } from '../../../../common/utils/hook-utils';
import { Application } from '../../types/application-types';
import { fetchApplicationList } from '../../network/applicationCrud';
import { debounce } from 'lodash';
import LoginDialog from '../../../../common/components/LoginDialog';
import { RedirectType } from '../../../../common/types/common-types';
import { useIntl } from 'react-intl';
import TokenHandler from '../../../../common/components/TokenHandler';
import { regionLocale, useServerLocale } from '../../../../i18n/utils/i18n-utils';
import LanguageSwitch from 'src/app/common/components/layout/components/container/components/header/language-switch';

const useStyles = makeStyles()(theme => ({
  root: {
    height: "100%",
    overflow: "hidden",
    position: "relative",
    backgroundColor: "#F4F4F4",
    color: "#333333",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 25,
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 20,
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 50,
    },
  },
  layoutContainer: {
    height: "calc(100% - 72px)",
    display: "flex",
    flexFlow: "column",
  },
  descriptionSection: {
    paddingTop: 40,
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 20,
      paddingRight: 80,
      marginBottom: 12,
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
      marginBottom: 20,
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 50,
      paddingRight: 50,
      marginBottom: 20,
    },
  },
  descriptionTitle: {
    fontSize: "1.5rem",
    lineHeight: 1.5,
    fontWeight: 400,
  },
  descriptionContent: {
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.5,
  },
  rowContainer: {
    display: "flex",
    alignItems: "center",
  },
  greyWrapper: {
    top: 0,
    right: 0,
    position: "absolute",
    width: "70%",
    height: "100%",
    zIndex: 1,
    backgroundColor: "#E6EAEE",
  },
  searchBarContainer: {
    position: "relative",
    zIndex: 2,
    padding: "12px 0 14px 0",
    [theme.breakpoints.up("xs")]: {
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
  searchBarMain: {
    margin: 0,
    position: "relative",
    [theme.breakpoints.up("xs")]: {
      width: "80%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80%",
      maxWidth: 500,
    },
  },
  searchBar: {
    backgroundColor: "#FFFFFF",
    border: 0,
    borderRadius: 16,
    padding: "12px 16px 12px 16px",
  },
  contentContainer: {
    flexGrow: 1,
    position: "relative",
    overflow: "hidden",
  },
  content: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-start",
    zIndex: 2,
    [theme.breakpoints.up("xs")]: {
      padding: 0,
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0 40px 0 40px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 50px 0 50px",
    },
  },
  applicationContainer: {
    position: "relative",
    flexGrow: 1,
    overflowY: "auto",
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
  },
  loginBtn: {
    fontWeight: 500,
    marginRight: 10,
    color: "#38527E",
    cursor: "pointer",
  },
  logoutBtn: {
    marginLeft: 10,
    fontWeight: 500,
    color: "#38527E",
    cursor: "pointer",
  },
  applicationInnerContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const DownloadPage: FC<RouteComponentProps> = ({ history, location, match }) => {
  const { classes } = useStyles();
  const locale = useServerLocale(true);
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const user = useSelector<RootState, TokenInfo | undefined>(
    (state) => state.auth.user
  );

  const [openDialog, setOpenDialog] = useState<boolean>(
    !!!localStorage.getItem("jwt")
  );
  const [applicationList, setApplicationList] = useState<Application[]>();

  const [keyword, setKeyword] = useState<string>("");

  const { isLoading, refreshData, error } = useDataProvider<Application[]>(
    () => {
      const condition = window.localStorage.getItem("jwt") ? "OWN" : "PUBLIC";
      return fetchApplicationList(condition, {
        name: keyword,
        role: "",
        bundleIdentifier: "",
        lang: locale,
      });
    },
    setApplicationList,
    false
  );

  //  eslint-disable-next-line
  const debounceRefresh = useCallback(
    debounce(() => {
      refreshData();
    }, 500),
    []
  );

  useUpdateEffect(() => {
    debounceRefresh();
  }, [keyword]);

  return (
    <>
      {/* <TokenHandler /> */}
      <LoginDialog
        open={openDialog}
        redirect={RedirectType.APPLICATION_DOWNLOAD}
        onClose={() => setOpenDialog(false)}
      />
      <div className={classes.root}>
        <div className={classes.header}>
          <Link href={"https://www.prudential.com.vn"}>
            <img
              style={{ width: 160 }}
              src={toAbsoluteUrl("/media/download-page/prudential.png")}
              alt={"Prudential Logo"}
            />
          </Link>
          <div className={classes.rowContainer}>
            {user ? (
              <div className={classes.loginBtn}>{user.username}</div>
            ) : (
                <div
                  className={classes.loginBtn}
                  onClick={() => setOpenDialog(true)}
                  >{Translation("common.login")}</div>
              )}
              {user &&
                <div
                  style={{ marginRight: 10 }}
                  className={classes.logoutBtn}
                  onClick={() => history.push(`/logout?redirect=${RedirectType.APPLICATION_DOWNLOAD}`)}
                >{Translation("common.logout")}</div>
              }
              <LanguageSwitch />
          </div>
        </div>
        <div className={classes.layoutContainer}>
          <div className={classes.descriptionSection}>
            <div
              style={{ marginBottom: 12 }}
              className={classes.descriptionTitle}
            >
              <span style={{ fontWeight: 800 }}>
                {Translation("downloadPage.title.download")}
              </span>{" "}
              {Translation("downloadPage.title")}
            </div>
            <div className={classes.descriptionContent}>
              {Translation("downloadPage.description")}
            </div>
          </div>
          <div className={classes.contentContainer}>
            <div className={classes.greyWrapper} />
            <div className={classes.content}>
              <div className={classes.searchBarContainer}>
                <TextField
                  variant="standard"
                  className={classes.searchBarMain}
                  placeholder={Translation("downloadPage.searchByKeywords")}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={toAbsoluteUrl(
                            "/media/download-page/search_icon.png"
                          )}
                          alt={"Search Icon"}
                        />
                      </InputAdornment>
                    ),
                    classes: {
                      root: classes.searchBar,
                    },
                  }}
                />
              </div>
              <div className={classes.applicationContainer}>
                {isLoading ? (
                  <div className={classes.applicationInnerContainer}>
                    <CircularProgress color="secondary" />
                  </div>
                ) : applicationList && applicationList.length > 0 ? (
                  applicationList.map((item) => (
                    <ApplicationItem
                      key={`application-item-${item.id}`}
                      applicationItem={item}
                      onOpenLoginDialog={() => user ?? setOpenDialog(true)}
                    />
                  ))
                ) : error ? (
                  <div className={classes.applicationInnerContainer}>
                    {error.message}
                  </div>
                ) : (
                    applicationList && applicationList.length > 0 ? (
                      applicationList.filter(item => item.allowAccess).map(item => (
                        <ApplicationItem
                          key={`application-item-${item.id}`}
                          applicationItem={item}
                          onOpenLoginDialog={() => user ?? setOpenDialog(true)}
                        />
                      ))
                    ) : (
                        (error as any) ? (
                          <div className={classes.applicationInnerContainer}>
                            {error?.['message']}
                          </div>
                        ) : (
                            <div className={classes.applicationInnerContainer}>
                              {Translation("downloadPage.noApp")}
                            </div>
                          )
                      )
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadPage;
