import { forEach } from "lodash";
import { apiClient, apiErrorHandler, CORE_URL } from "src/app/common/network";
import { Dispatch } from "react";
import { Application } from "../types/application-types";

export type ApplicationListParam = {
  bundleIdentifier: string;
  name: string;
  role: string;
  lang: string;
};

export const fetchApplicationList = async (
  mode: "PUBLIC" | "OWN",
  params: ApplicationListParam,
  dispatch?: Dispatch<any>
): Promise<Application[]> => {
  let queryPath =
    mode === "PUBLIC"
      ? `${CORE_URL}/application/public?`
      : `${CORE_URL}/application/me?`;
  forEach(params, (param, key) => {
    queryPath += param ? `${key}=${param}&` : "";
  });
  return apiClient
    .get<Application[]>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
