import React, { useEffect, useMemo, useRef, useState } from 'react';
import FormList from './components/Form';
import {
  EModuleTypes,
  EViewType,
  YesAndNo,
  LIBRARY_DEFAULT_CONFIG,
  RegisterPageType,
  EVENT_TEMPLATE_CONFIG,
  ResourceType,
  LinkType,
  LibraryPublishStatus,
  ProductType,
  CHECK_IN_URL,
  defaultTextColorOptions,
} from '../../constants';
import { Backdrop, Button, CircularProgress, Dialog } from '@mui/material';
import { regionLocale } from 'src/app/i18n';
import { FormMode, pathExtractor } from '@/app/common/utils/common-utils';
import { RouteComponentProps } from 'react-router-dom';
import { useLang } from 'src/app/i18n';
import {
  createNewLibrary,
  createNewTypeOfView,
  deleteLibrary,
  fetchLibraryItem,
  getBgImageById,
  getCoverpageByIdRequest,
  modifyLibrary,
  modifyTypeOfView,
  publishLibrary,
  unpublishLibrary,
} from '@/app/modules/ResourceLibrary/network/resourceCrud';
import { IBgImageDetail, ICoverpageDetail, LibraryEditItem, LibraryItem, ResourceLibraryFormState } from '../../types';
import { cloneDeep, forEach, get, pick, set } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { useIntl } from 'react-intl';
import { AlertType, appendAlertItem } from '@/redux/common/commonSlice';
import { useStyles } from './styles';
import moment from 'moment';
import { RootState } from '@/redux/store';

interface ResourceCreateProps
  extends RouteComponentProps<any, any, { selectedRows: any; type: string; allWeights: {} }> {
  blockName: string;
  blockType: string;
  addSuccessText: string;
  columnModuleType: string;
  categoryType: string;
  columnType: EModuleTypes;
  libraryBasePath: string;
  moduleType: string;
}

const initialState: any = {
  rType: 'link',
  category: '',
  updatedBy: '', // todo: need to update
  status: 'Draft',
  materialSet: [],
  agent: '',
  moduleColumn: [
    {
      column: '',
      weight: null,
    },
  ],
  typeOfViews: [
    {
      type: EViewType.GroupOfUsers,
    },
  ],
  eventInvitation: 'Y',
  checkInUrl: CHECK_IN_URL,
  eventInvitationRegisterPageType: RegisterPageType.template,
  eventForAd: true,
  eventInvitationTemplate: '',
  eventForOffices: [],
  backgroundImage: undefined,
  agendaList: [
    {
      time: null,
      content: '',
    },
  ],
  textColor: null,
};

const Create = ({
  history,
  location,
  blockName,
  blockType,
  addSuccessText,
  columnModuleType,
  categoryType,
  columnType,
  libraryBasePath,
  moduleType,
}: ResourceCreateProps) => {
  const { classes } = useStyles();
  const locale = useLang();
  const regionLocales: any[] = React.useMemo(() => {
    return regionLocale;
  }, []);
  const { formMode, code } = pathExtractor(location.pathname, libraryBasePath);
  const disabled = useMemo(() => {
    return formMode === FormMode.VIEW;
  }, [formMode]);
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const { officeCodes } = useSelector<RootState, any>((state: any) => {
    return {
      officeCodes: state.auth.officeCodes,
    };
  }, shallowEqual);

  const [loading, setLoading] = useState<boolean>(false);
  const [initData, setInitData] = useState<ResourceLibraryFormState>(
    regionLocales.reduce(
      (initData, current) => {
        initData[current] = { ...LIBRARY_DEFAULT_CONFIG };
        return initData;
      },
      { ...initialState, ...(officeCodes.length === 1 ? { eventForOffices: [officeCodes[0]] } : {}) },
    ),
  );
  const [isInitialDataSeted, setIsInitialDataSeted] = useState<boolean>(false);
  const [submitLocalizationsArray, setsubmitLocalizationsArray] = useState<string[]>([]);
  const [warningInvalidAgent, setShowWarningInvalidAgent] = useState({ isVisible: false, total: 0 });
  const isHaveInvalidAgRef = useRef(false);

  const queryData = async (resourceId: string) => {
    setLoading(true);
    const libraryDetail = await fetchLibraryItem(resourceId, dispatch).catch((err) => {
      console.log('Fetch library item fail ====>', err);
    });
    if (libraryDetail) {
      const param = await convertEditParams(libraryDetail, locale);

      let bgImageObj: any = {};
      if (libraryDetail.backgroundImage) {
        bgImageObj = await getBgImageById(libraryDetail.backgroundImage).catch((err) => {
          console.log('fetch BG image error ===>', err);
        });
        bgImageObj = parseImgDetailToOption(bgImageObj);
      }

      //@ts-ignore
      if (param) {
        setInitData({ ...param, backgroundImage: bgImageObj });
      }
    }

    setLoading(false);
    setIsInitialDataSeted(true);
  };

  const gpCoverpageId = useMemo(() => {
    const configString = window.localStorage.getItem('resourceConfig');
    const resourceConfig = JSON.parse(configString || '');
    return get(resourceConfig, 'resourceIdCoverpageSales');
  }, []);

  const parseCoverpageData = (data: ICoverpageDetail) => {
    const { _id, blobName, url, mimeType } = pick(data, ['_id', 'blobName', 'url', 'mimeType']);

    return {
      id: _id,
      fileName: blobName,
      url,
      type: mimeType,
      importId: _id,
    };
  };

  useEffect(() => {
    if (code) {
      queryData(code);
    } else if (gpCoverpageId) {
      getCoverpageByIdRequest(gpCoverpageId).then((res) => {
        const converteData = parseCoverpageData(get(res, '0'));
        setInitData((prev: any) => ({
          ...prev,
          ...regionLocales.reduce((cur: any, locale: any) => {
            cur[locale] = { ...prev[locale], coverpage: converteData };
            return cur;
          }, {}),
        }));
        setIsInitialDataSeted(true);
      });
    } else {
      setIsInitialDataSeted(true);
    }

    // eslint-disable-next-line
  }, [code, gpCoverpageId]);

  const parseImgDetailToOption = (data: IBgImageDetail) => {
    return data
      ? {
          index: 1,
          id: data._id,
          value: data._id,
          label: data.name,
          imgUrl: data.image.url,
          textColorList: data.textColorList
            ? data.textColorList.map((color) => ({ value: color, label: color }))
            : defaultTextColorOptions,
        }
      : undefined;
  };

  const loadingFun = () => {
    setLoading(true);
  };

  const randomWeight = () => {
    return '0.1' + moment().format('DDMMYYYYHHmmss');
  };

  const getTime = (timeStr?: string): Date | null => {
    if (!timeStr) {
      return null;
    }
    const hourMin = timeStr.split(':');
    if (hourMin.length !== 2) {
      return null;
    }
    const date = new Date();
    date.setHours(Number(hourMin[0]));
    date.setMinutes(Number(hourMin[1]));
    return date;
  };

  const gpInvitationId = useMemo(() => {
    const configString = window.localStorage.getItem('resourceConfig');
    const resourceConfig = JSON.parse(configString || '');
    return get(resourceConfig, 'categoryIdSales');
  }, []);

  const convertEditParams = async (rowData: LibraryItem, lang: string) => {
    let result: ResourceLibraryFormState = { ...initialState };
    const effectiveRange: [string, string] = [
      rowData.effectiveDate ? new Date(rowData.effectiveDate).toISOString() : '',
      rowData.expiredDate ? new Date(rowData.expiredDate).toISOString() : '',
    ];

    const eventDate: [string, string] = [
      rowData.eventStartDate ? new Date(rowData.eventStartDate).toISOString() : '',
      rowData.eventEndDate ? new Date(rowData.eventEndDate).toISOString() : '',
    ];

    const columnRef: any[] = (rowData.columnReferences as any[]).map((item, index) => {
      return {
        id: item?.column?.id,
        value: item?.column?.id,
        column: item?.column?.id,
        weight: item?.weight as number,
      };
    });

    let childItemsArray: any[] = [];
    //Reorder the childItems following the data.childItemList
    if (rowData?.childItems && rowData?.childItems?.length > 0) {
      if (rowData?.materialOrder && rowData?.materialOrder?.length > 0) {
        forEach(rowData.materialOrder, (item) => {
          const childItemDetail = (rowData.childItems as LibraryItem[]).find(
            (childItem: LibraryItem) => childItem.id === item || childItem?.uuid === item,
          );
          // const childItemDetail = (rowData.childItems as LibraryItem[]).find((childItem: LibraryItem) => (childItem.id === item ));
          if (childItemDetail) {
            childItemsArray.push(childItemDetail);
          }
        });
      } else {
        childItemsArray = rowData.childItems;
      }
    }
    result = {
      ...initialState,
      effectiveRange: effectiveRange,
      eventDate,
      rType: rowData.type ? rowData.type : '',
      category: rowData.category ? rowData.category.id : gpInvitationId, //hard code by "Thư mời GP"
      lastUpdatedBy: rowData.lastUpdatedBy ? rowData.lastUpdatedBy : '', // todo: need to update
      lastUpdatedBySub: rowData.lastUpdatedBySub ? rowData.lastUpdatedBySub : '', // todo: need to update
      status: rowData.status || '',
      materialSet: childItemsArray ? childItemsArray : [],
      agent: '',
      moduleColumn: columnRef,
      designations: rowData.designations,
      zones: rowData.zones,
      parentItems: rowData.parentItems ? rowData.parentItems : [],
      typeOfViews: rowData.typeOfViews || [],
      notificationMessage: rowData?.notificationMessage || '',
      eventInvitation: rowData.eventInvitation ? YesAndNo.yes : YesAndNo.no,
      checkInUrl: rowData.checkInUrl || '',
      eventInvitationTemplate: rowData?.eventInvitationTemplate || '',
      eventInvitationRegisterPageType: rowData?.eventInvitationRegisterPageType || '',
      agentType: rowData.agentType || '',
      targetAgentType: rowData.targetAgentType || '',
      targetAgentList: rowData.targetAgentList || [],
      agents: rowData.agents || [],
      includedAgents: rowData.includedAgents || [],
      excludedAgents: rowData.excludedAgents || [],
      eventForOffices: rowData.eventForOffices || [],
      backgroundImage: rowData.backgroundImage ? rowData.backgroundImage : '',
      agendaList: rowData.agendaList
        ? rowData.agendaList.map((element: any) => {
            element.time = getTime(element.time);
            return element;
          })
        : [],
      textColor: rowData.textColor || null,
    };

    //@ts-ignore
    result[rowData.locale] = {
      ...LIBRARY_DEFAULT_CONFIG,
      ...rowData.i18nData,
      name: rowData.name,
      eventAddress: rowData?.eventAddress || '',
      eventLocation: rowData?.eventLocation || '',
      canShared: rowData.canShare ? 'Y' : 'N',
      allowedForEProfile: rowData.canShare && rowData.allowedForEProfile ? 'Y' : 'N',
      coverpage: rowData.coverpage || undefined,
      introduction: rowData.introduction || '',
      tag: rowData.tag || [],
    };
    if (rowData?.localizations && rowData?.localizations?.length >= 1) {
      for (var index = 0; index < rowData?.localizations?.length; index++) {
        //@ts-ignore
        const itemResult = await fetchLibraryItem(rowData.localizations[index].id, dispatch);
        //@ts-ignore
        result[itemResult.locale] = {
          ...LIBRARY_DEFAULT_CONFIG,
          ...itemResult.i18nData,
          name: itemResult.name,
          canShared: itemResult.canShare ? 'Y' : 'N',
          eventAddress: itemResult?.eventAddress || '',
          eventLocation: itemResult?.eventLocation || '',
          allowedForEProfile: itemResult.canShare && itemResult.allowedForEProfile ? 'Y' : 'N',
          coverpage: itemResult.coverpage || undefined,
          introduction: itemResult.introduction || '',
          tag: itemResult.tag || [],
        };
        if (index === rowData?.localizations?.length - 1) {
          return result;
        }
      }
    } else {
      return result;
    }
  };

  const convertI18nData = (rType: string, localeData: any) => {
    let temp = {};
    if (rType === ResourceType.file || rType === ResourceType.picture) {
      temp = localeData.file ? { file: localeData.file, sharedIntroduction: localeData?.sharedIntroduction || '' } : {};
    } else if (rType === ResourceType.link) {
      let linkDetail;
      switch (localeData.linkType) {
        case LinkType.UrlWithVideo:
          linkDetail = { linkType: localeData.linkType, videos: localeData.videos };
          break;
        case LinkType.UrlWithInfor:
          linkDetail = {
            linkType: localeData.linkType,
            link: localeData.link,
            information: localeData.information,
            body: localeData.body,
          };
          break;
        case LinkType.Url:
        default:
          linkDetail = { linkType: localeData.linkType, link: localeData.link };
          break;
      }
      temp = {
        sharedIntroduction: localeData.sharedIntroduction,
        sharedTitle: localeData.sharedTitle,
        ...linkDetail,
      };
    } else if (rType === ResourceType.content || rType === ResourceType.text) {
      temp = { content: localeData.content, sharedIntroduction: localeData?.sharedIntroduction || '' };
    }
    set(temp, 'quickQuote', localeData.quickQuote);
    return temp;
  };

  const submitForm = async (formData: ResourceLibraryFormState) => {
    // @ts-ignore
    const localeData = formData[locale];
    setLoading(true);

    let jwtDetail: any;
    if (localStorage.getItem('jwt')) {
      jwtDetail = jwt_decode(localStorage.jwt);
    }

    const columnArray: string[] = [];
    const columnRef: any[] = [];
    formData.moduleColumn.map((item, index) => {
      if (item.column) {
        columnArray.push(item.column);
        return columnRef.push({
          column: item.column,
          module: moduleType,
          weight: (item.weight as number) || randomWeight(), // => need to random heree
        });
      } else {
        return null;
      }
    });
    // const hasFile = formData.rType === ResourceType.file || formData.rType === ResourceType.picture;
    let localeI18nData = {};
    if (formData.eventInvitationRegisterPageType === RegisterPageType.template) {
      if (formData.eventInvitationTemplate === 'TEMPLATE_1') {
        localeI18nData = {
          link: EVENT_TEMPLATE_CONFIG.TEMPLATE_1.url,
          sharedIntroduction: localeData.sharedIntroduction || '',
        };
      } else if (formData.eventInvitationTemplate === 'TEMPLATE_2') {
        localeI18nData = {
          link: EVENT_TEMPLATE_CONFIG.TEMPLATE_2.url,
          sharedIntroduction: localeData.sharedIntroduction || '',
        };
      } else if (formData.eventInvitationTemplate === 'TEMPLATE_3') {
        localeI18nData = {
          link: EVENT_TEMPLATE_CONFIG.TEMPLATE_3.url,
          sharedIntroduction: localeData.sharedIntroduction || '',
        };
      } else if (formData.eventInvitationTemplate === 'TEMPLATE_4') {
        localeI18nData = {
          link: EVENT_TEMPLATE_CONFIG.TEMPLATE_4.url,
          sharedIntroduction: localeData.sharedIntroduction || '',
        };
      }
    }

    const currentLocaleSubmitData: LibraryEditItem = {
      canShare: localeData.canShared === 'Y',
      allowedForEProfile: localeData.canShared === 'Y' && localeData.allowedForEProfile === 'Y',
      category: formData.category ? formData.category : gpInvitationId, //hard code by "Thư mời GP"
      columnReferences: columnRef?.length > 0 ? columnRef : [],
      coverpage: localeData.coverpage,
      designations: formData.designations || [],
      effectiveDate:
        formData.effectiveRange && formData.effectiveRange[0] ? new Date(formData.effectiveRange[0]) : null,
      expiredDate: formData.effectiveRange && formData.effectiveRange[1] ? new Date(formData.effectiveRange[1]) : null,
      i18nData: localeI18nData,
      introduction: localeData.introduction,
      module: moduleType,
      name: localeData.name,
      publishToSetOnly: formData.status === LibraryPublishStatus.PublishToSetOnly,
      tag: localeData.tag || [],
      type: formData.rType,
      lastUpdatedBy: jwtDetail.name || '',
      lastUpdatedBySub: jwtDetail.username || '',
      zones: formData.zones || [],
      columnIds: columnArray,
      childItems: [],
      materialOrder: [],
      status: formData.status,
      eventInvitation: formData.eventInvitation === 'Y',
      eventStartDate: formData.eventDate && formData.eventDate[0] ? new Date(formData.eventDate[0]) : null,
      eventEndDate: formData.eventDate && formData.eventDate[1] ? new Date(formData.eventDate[1]) : null,
      checkInUrl: formData.checkInUrl || '',
      eventInvitationTemplate: formData?.eventInvitationTemplate || '',
      eventAddress: localeData?.eventAddress || '',
      eventLocation: localeData?.eventLocation || '',
      eventInvitationRegisterPageType: formData?.eventInvitationRegisterPageType || '',
      // typeOfView: formData.typeOfView
      typeOfViews: [],
      productType: formData.productType || ProductType.Conventional,
      agentType: formData.agentType || '',
      targetAgentType: formData.targetAgentType || '',
      targetAgentList: formData.targetAgentList || [],
      agents: formData.agents || [],
      includedAgents: formData.includedAgents || [],
      excludedAgents: formData.excludedAgents || [],
      eventForOffices: formData.eventForOffices || [],
      eventForAd: formData.eventForAd,
      backgroundImage: formData.backgroundImage ? formData.backgroundImage.id : null,
      agendaList:
        formData.agendaList && formData.agendaList.length > 0
          ? formData.agendaList.reduce((accumulator: any[], currentValue: any) => {
              if (currentValue.time && currentValue.content && currentValue.content.trim() !== '') {
                accumulator.push({
                  ...currentValue,
                  time: moment(
                    typeof currentValue.time.toDate === 'function' ? currentValue.time.toDate() : currentValue.time,
                  ).format('HH:mm'),
                });
              }
              return accumulator;
            }, [])
          : [],
      textColor: formData.textColor || null,
    };

    currentLocaleSubmitData.notificationMessage = formData.notificationMessage || '';

    //Remove from material set if published
    if (formData.status === LibraryPublishStatus.Unpublished) {
      currentLocaleSubmitData[`parentItems`] = [];
    }

    const typeOfViewData = formData.typeOfViews;

    const item = typeOfViewData.find((x: any) => x.type === EViewType.GroupOfUsers);
    const userFile = item ? item.userFile : undefined;

    // check change typeOfView
    if (!code) {
      const payloadTypeOfView = {
        userFile,
        officeCodes: formData?.eventForOffices,
      };

      const newViewType = await createNewTypeOfView(payloadTypeOfView, dispatch);
      if (newViewType && newViewType.typeOfViewId) {
        currentLocaleSubmitData.typeOfViews?.push(newViewType.typeOfViewId);
      }

      if (newViewType.typeOfViewId === -1) {
        submitFail();
        return;
      }

      if (newViewType.totalNotValid) {
        setShowWarningInvalidAgent({
          isVisible: false,
          total: newViewType.totalNotValid,
        });
        isHaveInvalidAgRef.current = true;
      }
    }
    if (code) {
      let itemTypeOfView = initData?.typeOfViews?.find((x: any) => x.type === EViewType.GroupOfUsers);
      const updatedFile =
        get(itemTypeOfView, 'userFile.id') && get(itemTypeOfView, 'userFile.id') === get(item, 'userFile.id')
          ? undefined
          : userFile;

      const payload = {
        userFile: updatedFile,
        officeCodes: formData?.eventForOffices,
        typeOfViewId: itemTypeOfView?.id,
      };

      const viewType = await modifyTypeOfView(payload, dispatch);

      if (viewType.typeOfViewId === -1) {
        submitFail();
        return;
      }

      if (viewType && viewType.typeOfViewId) {
        currentLocaleSubmitData.typeOfViews?.push(viewType.typeOfViewId);
      }

      if (viewType.totalNotValid) {
        setShowWarningInvalidAgent({
          isVisible: false,
          total: viewType.totalNotValid,
        });
        isHaveInvalidAgRef.current = true;
      }
    }

    if (code) {
      //Edit
      await callSubmitEditAction(code, currentLocaleSubmitData, formData, true);
    } else {
      // Create
      await callSubmitCreateAction(currentLocaleSubmitData, formData, locale, true);
    }
  };

  const callSubmitEditAction = async (
    selectedId: string,
    sumbitData: LibraryEditItem,
    formData: ResourceLibraryFormState,
    isOriginalLocale: boolean = false,
  ) => {
    try {
      const resultData = await modifyLibrary(selectedId, sumbitData, dispatch);
      const localizationArray = submitLocalizationsArray;
      localizationArray.push(resultData.id as string);
      setsubmitLocalizationsArray(localizationArray);

      if (formData.status === LibraryPublishStatus.Unpublished && resultData.publishedAt) {
        //If existing is in Publish mode, and user want to save as draft
        await unpublishLibrary(resultData.id);
      }
      if (
        (formData.status === LibraryPublishStatus.Publish ||
          formData.status === LibraryPublishStatus.PublishToSetOnly) &&
        !resultData.publishedAt
      ) {
        //If existing is in draft mode, and user want to save as publish
        await publishLibrary(resultData.id);
      }
      if (isOriginalLocale) {
        //Call edit action for other language
        for (let i = 0; i < regionLocales?.length; i++) {
          const regionLocaleItem = regionLocales[i];
          if (regionLocaleItem !== resultData.locale) {
            const localeItem = resultData.localizations?.filter((item) => regionLocaleItem === item.locale)[0];
            // @ts-ignore
            const localeData = formData[regionLocaleItem];
            // const hasFile = formData.rType === ResourceType.file || formData.rType === ResourceType.picture;
            let localeI18nData = {};
            if (formData.eventInvitationRegisterPageType === RegisterPageType.template) {
              if (formData.eventInvitationTemplate === 'TEMPLATE_1') {
                localeI18nData = {
                  link: EVENT_TEMPLATE_CONFIG.TEMPLATE_1.url,
                  sharedIntroduction: localeData.sharedIntroduction || '',
                };
              } else if (formData.eventInvitationTemplate === 'TEMPLATE_2') {
                localeI18nData = {
                  link: EVENT_TEMPLATE_CONFIG.TEMPLATE_2.url,
                  sharedIntroduction: localeData.sharedIntroduction || '',
                };
              } else if (formData.eventInvitationTemplate === 'TEMPLATE_3') {
                localeI18nData = {
                  link: EVENT_TEMPLATE_CONFIG.TEMPLATE_3.url,
                  sharedIntroduction: localeData.sharedIntroduction || '',
                };
              } else if (formData.eventInvitationTemplate === 'TEMPLATE_4') {
                localeI18nData = {
                  link: EVENT_TEMPLATE_CONFIG.TEMPLATE_4.url,
                  sharedIntroduction: localeData.sharedIntroduction || '',
                };
              }
            } else {
              localeI18nData = convertI18nData(formData.rType, localeData);
            }

            let childItemList: string[] = [];
            let childItemUUIDList: string[] = [];
            if (formData.rType === ResourceType.materialSet) {
              for (let i = 0; i < formData.materialSet?.length; i++) {
                for (let j = 0; j < formData?.materialSet[i]?.localizations?.length; j++) {
                  const id = formData.materialSet[i].localizations[j].id;
                  const localizationsItemDetail = await fetchLibraryItem(id);

                  if (localizationsItemDetail.locale === regionLocaleItem) {
                    childItemList.push(id);
                    childItemUUIDList.push(localizationsItemDetail.uuid ? localizationsItemDetail.uuid : id);
                    // Todo: need to handle for LBU ,(use uuid for HK only)
                  }
                }
              }
            }

            const newLocalSubmitData = {
              ...sumbitData,
              name: localeData.name,
              i18nData: localeI18nData,
              canShare: localeData.canShared === 'Y',
              allowedForEProfile: localeData.canShared === 'Y' && localeData.allowedForEProfile === 'Y',
              introduction: localeData.introduction,
              tag: localeData.tag,
              coverpage: localeData.coverpage,
              localizations: localizationArray,
              childItems: childItemList ? childItemList : [],
              materialOrder: childItemUUIDList ? childItemUUIDList : [],
              eventAddress: localeData?.eventAddress || '',
              eventLocation: localeData?.eventLocation || '',
            };

            //Remove from material set if published
            if (formData.status === LibraryPublishStatus.Unpublished) {
              newLocalSubmitData[`parentItems`] = [];
            }

            if (localeItem) {
              await callSubmitEditAction(localeItem.id, newLocalSubmitData, formData, false);
            } else {
              await callSubmitCreateAction(newLocalSubmitData, formData, regionLocaleItem, false, resultData);
            }
          }
        }
        submitSuccess();
      }
    } catch (err) {
      console.log(err);
      submitFail();
    }
  };

  const callSubmitCreateAction = async (
    sumbitData: LibraryEditItem,
    formData: ResourceLibraryFormState,
    locale: string,
    isOriginalLocale: boolean = false,
    prevResultData?: LibraryItem,
  ) => {
    try {
      const resultData = await createNewLibrary(sumbitData, locale, prevResultData ? prevResultData.id : '', dispatch);
      const localizationArray = submitLocalizationsArray;
      localizationArray.push(resultData.id as string);
      setsubmitLocalizationsArray(localizationArray);

      if (
        formData.status === LibraryPublishStatus.Publish ||
        formData.status === LibraryPublishStatus.PublishToSetOnly
      ) {
        // call publish again
        await publishLibrary(resultData.id, dispatch);
      }
      if (isOriginalLocale) {
        //Call create action for other language
        for (let i = 0; i < regionLocales?.length; i++) {
          const localItem = regionLocales[i];
          if (localItem !== locale) {
            // @ts-ignore
            const localeData = formData[localItem];
            // const hasFile = formData.rType === ResourceType.file || formData.rType === ResourceType.picture;
            let localeI18nData = {};
            if (formData.eventInvitationRegisterPageType === RegisterPageType.template) {
              if (formData.eventInvitationTemplate === 'TEMPLATE_1') {
                localeI18nData = {
                  link: EVENT_TEMPLATE_CONFIG.TEMPLATE_1.url,
                  sharedIntroduction: localeData.sharedIntroduction || '',
                };
              } else if (formData.eventInvitationTemplate === 'TEMPLATE_2') {
                localeI18nData = {
                  link: EVENT_TEMPLATE_CONFIG.TEMPLATE_2.url,
                  sharedIntroduction: localeData.sharedIntroduction || '',
                };
              } else if (formData.eventInvitationTemplate === 'TEMPLATE_3') {
                localeI18nData = {
                  link: EVENT_TEMPLATE_CONFIG.TEMPLATE_3.url,
                  sharedIntroduction: localeData.sharedIntroduction || '',
                };
              } else if (formData.eventInvitationTemplate === 'TEMPLATE_4') {
                localeI18nData = {
                  link: EVENT_TEMPLATE_CONFIG.TEMPLATE_4.url,
                  sharedIntroduction: localeData.sharedIntroduction || '',
                };
              }
            } else {
              localeI18nData = convertI18nData(formData.rType, localeData);
            }

            let childItemList: string[] = [];
            let childItemUUIDList: string[] = [];
            if (formData.rType === ResourceType.materialSet) {
              for (let i = 0; i < formData?.materialSet?.length; i++) {
                for (let j = 0; j < formData?.materialSet[i]?.localizations?.length; j++) {
                  const id = formData.materialSet[i].localizations[j].id;
                  const localizationsItemDetail = await fetchLibraryItem(id);
                  if (localizationsItemDetail.locale === localItem) {
                    childItemList.push(id);
                    childItemUUIDList.push(localizationsItemDetail.uuid ? localizationsItemDetail.uuid : id);
                    // Todo: need to handle for LBU ,(use uuid for HK only)
                  }
                }
              }
            }

            const newLocalSubmitData = {
              ...sumbitData,
              name: localeData.name,
              i18nData: localeI18nData,
              canShare: localeData.canShared === 'Y',
              allowedForEProfile: localeData.canShared === 'Y' && localeData.allowedForEProfile === 'Y',
              introduction: localeData.introduction,
              tag: localeData.tag,
              localizations: localizationArray,
              coverpage: localeData.coverpage,
              childItems: childItemList ? childItemList : [],
              materialOrder: childItemUUIDList ? childItemUUIDList : [],
              eventAddress: localeData?.eventAddress || '',
              eventLocation: localeData?.eventLocation || '',
            };

            //Remove from material set if published
            if (formData.status === LibraryPublishStatus.Unpublished) {
              newLocalSubmitData[`parentItems`] = [];
            }
            try {
              await callSubmitCreateAction(newLocalSubmitData, formData, localItem, false, resultData);
            } catch (err) {
              await deleteLibrary(resultData.id);
              console.log(err);
              submitFail();
            }
          }
        }
        submitSuccess();
      }
    } catch (err) {
      console.log(err);
      submitFail();
    }
  };

  const submitSuccess = async () => {
    setsubmitLocalizationsArray([]);
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: Translation('global.submit.success'),
          content: Translation(addSuccessText),
        },
      ]),
    );

    setLoading(false);

    if (!isHaveInvalidAgRef.current) {
      history.push(libraryBasePath);
    } else {
      setShowWarningInvalidAgent((prev) => ({ ...prev, isVisible: true }));
    }
  };

  const submitFail = () => {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.ERROR,
          title: Translation('global.submit.fail'),
          content: Translation('global.submit.fail'),
        },
      ]),
    );
    setLoading(false);
  };

  return (
    <div className={classes.formContainer}>
      {isInitialDataSeted && (
        <FormList
          initialValues={cloneDeep(initData)}
          onSave={submitForm}
          disabled={disabled}
          blockName={blockName}
          categoryType={categoryType}
          blockType={blockType}
          columnType={columnType}
          isEdit={formMode === FormMode.EDIT}
          history={history}
          loadingFun={loadingFun}
          moduleType={moduleType}
          currentId={code || ''}
        />
      )}

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" className={'d-flex'} />
      </Backdrop>
      <Dialog
        onClose={() => {
          setShowWarningInvalidAgent((prev) => ({ ...prev, isVisible: false }));
          setTimeout(() => {
            isHaveInvalidAgRef.current = false;
            history.push(libraryBasePath);
          }, 500);
        }}
        open={warningInvalidAgent.isVisible}
      >
        <span className={classes.warningTitle}>{Translation('app.globalText.warning')}</span>
        <span
          className={classes.warningTxt}
        >{`Có ${warningInvalidAgent.total} TVV trong danh sách TVV đã tải lên không thuộc Văn phòng đã chọn`}</span>
        <Button
          onClick={() => {
            setShowWarningInvalidAgent((prev) => ({ ...prev, isVisible: false }));
            setTimeout(() => {
              isHaveInvalidAgRef.current = false;
              history.push(libraryBasePath);
            }, 500);
          }}
          className={classes.btnDialog}
        >
          {Translation('global.text.okay')}
        </Button>
      </Dialog>
    </div>
  );
};

export default Create;
