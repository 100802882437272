import { Dispatch } from 'react';
import {
  BgImagePaginateList,
  ColumnPaginateList,
  IBgImageDetail,
  IBgImageRequestParams,
  ICoverpageDetail,
  ITypeOfView,
  LibraryEditItem,
  LibraryItem,
  LibraryListParam,
  LibraryPaginateList,
} from '../pages/SalesMaterial/types';
import { filterQueryForPublish } from '../utils/common-utils';
import { forEach } from 'lodash';
import { getDayEnd, getDayStartForStrapi, ignoreTimezone } from '@/app/common/utils/time-utils';
import moment from 'moment';
import qs from 'qs';
import { apiClient, apiErrorHandler } from 'src/app/common/network';
import { CreateBlobParams, GetBlobParams } from 'src/app/common/network';
import { ResourceCreateBlobRes, ResourceGetBlobRes } from '@/app/common/resource/resource-types';

const AGENT_URL = window.envConfig['REACT_APP_APIM_BASE_URL'];
const APIM_AGENT = window.envConfig['REACT_APP_APIM_AGENT'];

export const fetchLibraryList = async (
  locale: string,
  params: LibraryListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<LibraryPaginateList> => {
  let queryPath = `${AGENT_URL}${APIM_AGENT}/content-manager/collection-types/api::material.material?`;

  // Query for active status filter
  queryPath += filterQueryForPublish(params['status'], queryPath);

  // Query for event invitation
  queryPath += `filters[eventInvitation][$eq]=true&`;

  // Query for event invitation
  queryPath += `filters[eventForAd][$eq]=true&`;

  // Query for event for office
  let officeCodes = params.officeCodes || [];
  for (let i = 0; i < officeCodes.length; i++) {
    queryPath += `filters[eventForOffices][$containsi]=${officeCodes[i]}&`;
  }

  // Query for other filter
  forEach(params, (param, key) => {
    // console.log(param, key)
    if (key === 'name') {
      const escapedParam = (param as string).replace(/([!@#$%^&*()+=\[\]\\';,./{}|":<>?~_-])/g, '\\$1');
      // queryPath += param ? `${key}_contains=${escapedParam}&` : '';
      queryPath += param ? `filters[${key}][$containsi]=${escapedParam}&` : '';
    } else if (typeof param === 'object' && !Array.isArray(param)) {
      if (param) {
        if (key.toLowerCase().includes('createtimestart') && !isNaN(param.getTime())) {
          // queryPath += `_where[0][createdAt_gte]=${getDayStartForStrapi(param)}&`;
          queryPath += `filters[createdAt][$gte]=${getDayStartForStrapi(param)}&`;
        } else if (key.toLowerCase().includes('createtimeend') && !isNaN(param.getTime())) {
          // queryPath += `_where[0][createdAt_lte]=${ignoreTimezone(getDayEnd(param))}&`;
          queryPath += `filters[createdAt][$lte]=${ignoreTimezone(getDayEnd(param))}&`;
        } else if (key.toLowerCase().includes('effectivedate') && !isNaN(param.getTime())) {
          const curTime = moment(param).format('YYYY-MM-DDThh:mm:ss.sss');
          const query = qs.stringify({
            filters: {
              status: 'Published',
              $or: [
                {
                  effectiveDate: {
                    $lte: curTime,
                  },
                  expiredDate: {
                    $gte: curTime,
                  },
                },
                {
                  effectiveDate: {
                    $lte: curTime,
                  },
                  expiredDate: {
                    $null: true,
                  },
                },
                {
                  effectiveDate: {
                    $null: true,
                  },
                  expiredDate: {
                    $gte: curTime,
                  },
                },
                {
                  effectiveDate: {
                    $null: true,
                  },
                  expiredDate: {
                    $null: true,
                  },
                },
              ],
            },
          });
          queryPath += `${query}&`;
        } else if (key === 'type_eq') {
          forEach(param, (param, index) => {
            queryPath += param ? `${key}=${param}&` : '';
          });
        }
      }
    } else if (key !== 'isPublish' && key !== 'isValid') {
      if (typeof param === 'string') {
        if (key === 'category') {
          // queryPath += param ? `category.name_eq=${param}&` : '';
          queryPath += param ? `filters[category][name][$eq]=${param}&` : '';
        } else if (key === 'status') {
        } else if (key === 'eventInvitation') {
        } else if (key === 'column') {
          if (param) {
            const query = {
              populate: {
                columnReferences: {
                  populate: '*',
                },
              },
              filters: {
                columnReferences: {
                  column: {
                    id: param,
                  },
                },
              },
            };
            queryPath += `${qs.stringify(query)}&`;
          }
        } else if (key == 'type_eq') {
          queryPath += param ? `${key}=${param}&` : '';
        } else {
          // queryPath += param ? `${key}_contains=${param}&` : '';
          queryPath += param ? `filters[${key}][$containsi]=${param}&` : '';
        }
      } else {
        queryPath += param ? `${key}=${param}&` : '';
      }
    }
  });

  //Query for sorting
  let sortingCount = 0;
  queryPath += `sort=`;
  forEach(sortKeys, (sortParam, key) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${sortParam.key}:${sortParam.value}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `updatedAt:DESC`;
  }

  // Query for current language
  if (locale !== '') {
    queryPath += `&locale=${locale}`;
  }

  return apiClient
    .get(queryPath)
    .then((response: any) => {
      let libraryList = response.data.results;
      const data = {
        libraryList: libraryList,
        total: response.data.pagination.total,
        totalPages: response.data.pagination.pageCount,
        limit: response.data.pagination.pageSize,
        items: response.data.results,
        totalRecords: response.data.pagination.total,
        docs: response.data.results,
        hasNextPage: true,
        hasPrevPage: false,
        totalDocs: response.data.pagination.total,
      };

      if (libraryList.length == 0) {
        return data;
      }

      const uuid = libraryList.map((result: { uuid: string }) => result.uuid);
      const columnReferencesUrl = `${AGENT_URL}${APIM_AGENT}/content-manager/api/materials?${qs.stringify({
        fields: ['uuid'],
        populate: {
          columnReferences: {
            populate: '*',
          },
        },
        filters: {
          uuid,
        },
        publicationState: 'preview',
        pagination: {
          pageSize: uuid.length || 50,
        },
      })}`;
      return apiClient
        .get(columnReferencesUrl, {
          headers: {
            'raw-path': true,
          },
        })
        .then((res: any) => {
          const formattedData = {
            data: res.data.data.map((item: { id: string; attributes: { columnReferences: any[]; uuid: string } }) => {
              const formattedColumnReferences = item.attributes.columnReferences.map((reference) => {
                const columnAttributes = reference.column.data?.attributes;
                if (columnAttributes) {
                  columnAttributes.id = reference.column.data.id;
                }

                return {
                  id: reference.id,
                  weight: reference.weight,
                  module: reference.module,
                  column: columnAttributes || null,
                };
              });
              return {
                id: item.id,
                attributes: {
                  uuid: item.attributes.uuid,
                  columnReferences: formattedColumnReferences,
                },
              };
            }),
            meta: res.data.meta,
          };

          // Modify libraryList with column references
          forEach(data.libraryList, (libraryItem) => {
            const columnReferences = formattedData.data.find(
              (item: { attributes: { uuid: string; columnReferences: any[] } }) =>
                item.attributes.uuid === libraryItem.uuid,
            );
            libraryItem.columnReferences = columnReferences?.attributes?.columnReferences;
          });

          return data;
        })
        .catch((error: any) => {
          console.log('error:', error);
          throw error;
        });
    })
    .catch((error: any) => {
      console.log('error:', error);
      throw error;
    });
};

export const fetchAllColumnList = async (moduleType: string, dispatch?: Dispatch<any>): Promise<ColumnPaginateList> => {
  let queryPath = `${AGENT_URL}${APIM_AGENT}/content-manager/collection-types/api::column.column?`;

  // Query for corresponding module
  // queryPath += `module_eq=${moduleType}`;
  queryPath += `filters[module][$eq]=${moduleType}&page=1&pageSize=1000`;

  return apiClient
    .get(queryPath)
    .then((response: any) => {
      return {
        columnList: response.data.results,
        total: response.data.pagination.total,
        totalPages: response.data.pagination.pageCount,
        limit: response.data.pagination.pageSize,
        items: response.data.results,
        totalRecords: response.data.pagination.total,
        docs: response.data.results,
        hasNextPage: true,
        hasPrevPage: false,
        totalDocs: response.data.pagination.total,
      };
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyLibrary = async (
  code: string,
  data: LibraryEditItem,
  dispatch?: Dispatch<any>,
): Promise<LibraryItem> => {
  const modifyURL = `${AGENT_URL}${APIM_AGENT}/content-manager/collection-types/api::material.material/${code}`;
  return apiClient
    .put<LibraryItem>(modifyURL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response: any) => {
      return response.data;
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const editUnpublishLibrary = async (
  dataId: string,
  data: LibraryEditItem,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  const publishURL = `${AGENT_URL}${APIM_AGENT}/content-manager/collection-types/api::material.material/${dataId}/actions/unpublish`;
  return apiClient
    .post<void>(publishURL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response: any) => {
      return response.data;
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchLibraryItem = (id: string, dispatch?: Dispatch<any>): Promise<LibraryItem> => {
  const queryPath = `${AGENT_URL}${APIM_AGENT}/content-manager/api/materials/${id}?${qs.stringify({
    populate: {
      columnReferences: {
        populate: '*',
      },
      childItems: {
        populate: '*',
      },
      category: {
        populate: '*',
      },
      localizations: {
        populate: '*',
      },
      typeOfViews: {
        populate: '*',
      },
    },
  })}`;

  return apiClient
    .get(queryPath, {
      headers: {
        'raw-path': true,
      },
    })
    .then((response: any) => {
      const item = response.data.data.attributes;
      if (item.category.data) {
        item.category.data.attributes.id = item.category.data.id;
        item.category = item.category.data.attributes;
      }

      item.typeOfViews =
        item?.typeOfViews?.data?.map((typeOfView: any) => {
          return { ...typeOfView?.attributes, id: typeOfView.id };
        }) || [];

      item.columnReferences = item.columnReferences.map(
        (i: { column: { data: { id: string; attributes: any } }; module: string; weight: number; id: string }) => {
          i.column.data.attributes.id = i.column.data.id;
          i.column = i.column.data.attributes;
          return i;
        },
      );
      item.childItems = item.childItems.data.map((material: any) => {
        material.attributes.id = material.id;
        material.attributes.localizations = (material.attributes.localizations?.data || []).map((lo: any) => {
          lo.attributes.id = lo.id;
          return lo.attributes;
        });
        return material.attributes;
      });
      item.localizations = item.localizations.data.map((i: { id: string; attributes: any }) => {
        i.attributes.id = i.id;
        return i.attributes;
      });
      return item;
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const publishLibrary = async (dataId: string, dispatch?: Dispatch<any>): Promise<void> => {
  const publishURL = `${AGENT_URL}${APIM_AGENT}/content-manager/collection-types/api::material.material/${dataId}/actions/publish`;
  return apiClient
    .post<void>(
      publishURL,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response: any) => {
      return response.data;
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const unpublishLibrary = async (dataId: string, dispatch?: Dispatch<any>): Promise<void> => {
  const unpublishURL = `${AGENT_URL}${APIM_AGENT}/content-manager/collection-types/api::material.material/${dataId}/actions/unpublish`;
  return apiClient
    .post<void>(
      unpublishURL,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response: any) => {
      return response.data;
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewLibrary = async (
  data: LibraryEditItem,
  locale: string,
  relatedId?: string,
  dispatch?: Dispatch<any>,
): Promise<LibraryItem> => {
  let createURL = `${AGENT_URL}${APIM_AGENT}/content-manager/collection-types/api::material.material?plugins[i18n][locale]=${locale}`;
  if (relatedId) {
    createURL += `&plugins[i18n][relatedEntityId]=${relatedId}`;
  }

  return apiClient
    .post<LibraryItem>(createURL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response: any) => {
      return response.data;
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteLibrary = async (dataId: string, dispatch?: Dispatch<any>): Promise<void> => {
  const deleteURL = `${AGENT_URL}${APIM_AGENT}/content-manager/collection-types/api::material.material/${dataId}`;
  return apiClient
    .delete<void>(deleteURL, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response: any) => {
      return response.data;
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchAllColumns = async (module: string) => {
  const query = {
    publicationState: 'preview',
    pagination: {
      limit: -1,
    },
    filters: {
      module,
      publishedAt: {
        $notNull: true,
      },
    },
  };

  const queryPath = `${AGENT_URL}${APIM_AGENT}/content-manager/api/columns?${qs.stringify(query)}`;

  return apiClient
    .get(queryPath, {
      headers: {
        'raw-path': true,
      },
    })
    .then((response: any) => {
      const data = response.data;
      data.data = (data.data || []).map((item: any) => {
        item.attributes.id = item.id;
        item.attributes._id = item.id;
        return item.attributes;
      });

      return data.data;
    });
};

export const getBgImageById = async (id: string): Promise<IBgImageDetail> => {
  const queryPath = `${AGENT_URL}${APIM_AGENT}/ad/background-images/${id}`;

  return apiClient
    .get(queryPath, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response: any) => {
      return response.data;
    });
};

export const fetchAllBgImageRequest = async ({
  name = '',
  page = 1,
  limit = 20,
  type,
}: IBgImageRequestParams): Promise<BgImagePaginateList> => {
  const query = {
    name,
    page,
    limit,
    type,
  };

  const queryPath = `${AGENT_URL}${APIM_AGENT}/ad/background-images?${qs.stringify(query)}`;

  return apiClient
    .get(queryPath, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response: any) => {
      return response.data ? { ...response.data, page, limit } : {};
    });
};

export const createBlob = async (
  params: CreateBlobParams,
  dispatch?: Dispatch<any>,
): Promise<ResourceCreateBlobRes> => {
  return apiClient
    .post<ResourceCreateBlobRes>(`${AGENT_URL}${APIM_AGENT}/ad/resource`, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response: any) => response.data)
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getBlob = async (params: GetBlobParams, dispatch?: Dispatch<any>): Promise<ResourceGetBlobRes[]> => {
  return apiClient
    .get<ResourceGetBlobRes[]>(`${AGENT_URL}${APIM_AGENT}/ad/resource`, { params })
    .then((response: any) => response.data)
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewTypeOfView = async (data: ITypeOfView, dispatch?: Dispatch<any>): Promise<ITypeOfView> => {
  let createURL = `${AGENT_URL}${APIM_AGENT}/ad/material/handle-type-of-view`;
  return apiClient
    .post<ITypeOfView>(createURL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response: any) => {
      return response.data;
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyTypeOfView = async (data: ITypeOfView, dispatch?: Dispatch<any>): Promise<ITypeOfView> => {
  const modifyURL = `${AGENT_URL}${APIM_AGENT}/ad/material/handle-type-of-view`;
  return apiClient
    .post<ITypeOfView>(modifyURL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response: any) => {
      return response.data;
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getCoverpageByIdRequest = async (id: string, dispatch?: Dispatch<any>): Promise<ICoverpageDetail[]> => {
  const modifyURL = `${AGENT_URL}${APIM_AGENT}/ad/resource?resourceIds=${id}`;
  return apiClient
    .get<ICoverpageDetail[]>(modifyURL, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response: any) => {
      return response.data;
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};
