import React, { FC, HTMLAttributes } from "react";
import { makeStyles } from 'tss-react/mui';
import { useIntl } from "react-intl";

type ApplicationTagProps = {
  tag: "latest" | "ipad";
};

const useStyles = makeStyles()(theme => ({
  latestTag: {
    borderRadius: 32,
    padding: "2px 15px 2px 15px",
    backgroundColor: "#D0F6DF",
    color: "#21BA5E",
    whiteSpace: "nowrap",
  },
  ipadTag: {
    borderRadius: 32,
    padding: "2px 15px 2px 15px",
    backgroundColor: "#D2E8FF",
    color: "#3994F2",
    whiteSpace: "nowrap",
  },
}));

const ApplicationTag: FC<ApplicationTagProps & HTMLAttributes<HTMLDivElement>> = ({ tag, ...rest }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <>
      {tag === "latest" && (
        <div className={classes.latestTag} {...rest}>
          {Translation("downloadPage.tag.latest")}
        </div>
      )}
      {tag === "ipad" && (
        <div className={classes.ipadTag} {...rest}>
          {Translation("downloadPage.tag.iPad")}
        </div>
      )}
    </>
  );
};

export default ApplicationTag;
