import React, { useRef, useState } from 'react';
import { useStyles } from './styles';
import { useIntl } from 'react-intl';
import Form from '@common/components/Form';
import { Button, Chip, FormControlLabel, MenuItem, Radio, RadioGroup, TextField } from '@mui/material';
import FileUploader, { FileObj } from '@/app/common/components/FileUploader';
import { ColumnOptions } from '.';
import DateTimeRangePicker from '../DateTimeRangePicker';
import ButtonDialog from '@components/ButtonDialog';
import { FormInstance } from 'rc-field-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { createBlob, getBlob } from '@/app/modules/ResourceLibrary/network/resourceCrud';
import { fileUpload } from '@/app/common/resource/resource-utils';
import { EViewType, FEEDBACK_URL } from '../../../../constants';
import { get, debounce } from 'lodash';
import moment from 'moment';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import SelectBoxOffices from '@/app/common/components/SelectBoxOffices';
import ClearIcon from '@mui/icons-material/Clear';

type IPublishSettings = {
  disabled: boolean;
  columnOptions: ColumnOptions;
  publishItems: any;
  onCancel: () => void;
  form: FormInstance<any>;
  moduleType: string;
  onSubmit: (param: any) => void;
};

const PublishSettings = ({
  disabled,
  columnOptions,
  publishItems,
  onCancel,
  form,
  moduleType,
  onSubmit,
}: IPublishSettings) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const agendaListLabel = Translation('component.formLabel.agendaList');
  const { officeCodes } = useSelector<RootState, any>((state) => {
    return {
      officeCodes: state.auth.officeCodes,
    };
  }, shallowEqual);
  let selectOfficesRef: any = useRef(null);
  const [officesOptions] = useState<any[]>(
    officeCodes && officeCodes.length > 0
      ? officeCodes.map((item: any, index: number) => ({
          index: index + 1,
          value: item,
          label: item,
        }))
      : [],
  );
  const [officesSelected, setOfficesSelected] = useState<string[]>(form.getFieldValue('eventForOffices'));

  const getInitUserFile = function () {
    const typeOfViewData = form.getFieldValue('typeOfViews') || [];

    const item = typeOfViewData.find((x: any) => x.type === EViewType.GroupOfUsers);
    if (item) {
      return item.userFile || undefined;
    }
    return undefined;
  };

  const [userFile, SetUserFile] = useState<FileObj | undefined>(getInitUserFile());

  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };
  const DATE_VIDATOR = [
    {
      validator(_: any, value: string) {
        if (value && get(value, '0') && get(value, '1')) {
          if (moment(get(value, '0')).isAfter(moment(get(value, '1')))) {
            return Promise.reject(new Error(Translation('resource.library.endDateAfterStartDateMessage')));
          }
          return Promise.resolve();
        }
        return Promise.reject(new Error(Translation('component.form-required-text')));
      },
    },
  ];

  const EVENT_DATE_VIDATOR = [
    {
      validator(_: any, value: string) {
        if (value && get(value, '0') && get(value, '1')) {
          if (moment(get(value, '0')).isAfter(moment(get(value, '1')))) {
            return Promise.reject(new Error(Translation('resource.library.endDateAfterStartDateMessage')));
          }
          return Promise.resolve();
        }
        return Promise.reject(new Error(Translation('component.form-required-text')));
      },
    },
    {
      validator(_: any, value: string) {
        const [effectiveFrom, effectiveTo] = form.getFieldValue('effectiveRange') || [];
        if (value && get(value, '0') && get(value, '1')) {
          if (
            effectiveFrom &&
            effectiveTo &&
            (moment(get(value, '0')).isBefore(moment(effectiveFrom)) ||
              moment(get(value, '0')).isAfter(moment(effectiveTo)))
          ) {
            return Promise.reject(new Error(Translation('resource.library.eventRangeBetweenEffectiveRangeMessage')));
          }

          if (
            effectiveFrom &&
            effectiveTo &&
            (moment(get(value, '1')).isBefore(moment(effectiveFrom)) ||
              moment(get(value, '1')).isAfter(moment(effectiveTo)))
          ) {
            return Promise.reject(new Error(Translation('resource.library.eventRangeBetweenEffectiveRangeMessage')));
          }
        }
        return Promise.resolve();
      },
    },
  ];

  const TYPE_OF_VIEW_RULES = [
    { required: true, message: Translation('component.form-required-text') },
    {
      validator(_: any, value: any) {
        const item = value?.find((x: any) => x.type === EViewType.GroupOfUsers);
        if (item && item.userFile) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(Translation('component.form-required-text')));
      },
    },
  ];

  const getTimeAgendaRules = (index: number) => {
    return [
      {
        validator(_: any, value: string) {
          const agendaListValue = form.getFieldValue('agendaList');

          if (agendaListValue[index].content.trim() !== '' && !agendaListValue[index].time) {
            return Promise.reject(new Error(Translation('component.form-required-text')));
          }

          if (isNaN(agendaListValue[index].time)) {
            return Promise.reject(new Error('Invalid format'));
          }

          if (!agendaListValue[index].time && agendaListValue[index].content.trim() === '') {
            const errorList = form.getFieldError(['agendaList', index, 'content']);

            if (errorList.length > 0) {
              form.resetFields([['agendaList', index, 'content']]);
            }
          }

          return Promise.resolve();
        },
      },
    ];
  };

  const getContentAgendaRules = (index: number) => {
    return [
      {
        validator(_: any, value: string) {
          const agendaListValue = form.getFieldValue('agendaList');

          if (agendaListValue[index].time && agendaListValue[index].content.trim() === '') {
            return Promise.reject(new Error(Translation('component.form-required-text')));
          }

          if (!agendaListValue[index].time && agendaListValue[index].content.trim() === '') {
            const errorList = form.getFieldError(['agendaList', index, 'time']);

            if (errorList.length > 0) {
              form.resetFields([['agendaList', index, 'time']]);
              agendaListValue[index].time = null;
              form.setFieldsValue({ agendaList: [...agendaListValue] });
            }
          }

          return Promise.resolve();
        },
      },
    ];
  };

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        {
          mimeType: fileType,
          accessLevel: 'anonymous',
          module: `material_${moduleType.toLowerCase()}`,
        },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];

      if (result) {
        return {
          id: result.blobId,
          fileName: fileName,
          url: result.url,
          type: fileType,
        };
      }
    } catch (err) {
    } finally {
    }
  };

  const onNavigateFeedback = () => {
    window.open(FEEDBACK_URL, '_blank', 'noopener,noreferrer');
  };

  const getValidRange = (obj: FormInstance<any>, type: string) => {
    const eventRange = obj.getFieldValue('eventDate');
    const effectiveRange = obj.getFieldValue('effectiveRange');

    if (type === 'eventDate' && effectiveRange) {
      return {
        minFromDate: get(effectiveRange, '0') || undefined,
        maxToDate: get(effectiveRange, '1') || undefined,
        maxFromDate: get(eventRange, '1') || get(effectiveRange, '1') || undefined,
      };
    } else if (type === 'effectiveRange' && eventRange) {
      return {
        maxFromDate: get(eventRange, '0') || undefined,
        minToDate: get(eventRange, '1') || undefined,
      };
    }
    return {};
  };

  const resetFileGroupUsers = () => {
    if (userFile) {
      form.setFieldValue('typeOfViews', [
        {
          type: EViewType.GroupOfUsers,
        },
      ]);
      SetUserFile(undefined);
    }
  };

  return (
    <React.Fragment>
      <div className={`${classes.container} ${classes.sectionWrap}`}>
        <div className={classes.divideMargin} style={{ height: '32px', lineHeight: '32px' }}>
          <span className={classes.subHeader}>{Translation('component.formTitle.publishment-settings')}</span>
        </div>

        <Form.Item
          childStyle={{ marginTop: 16, minHeight: 32, height: 'auto', alignItems: 'flex-start' }}
          name="eventForOffices"
          label={Translation('component.formLabel.event-for-office')}
          rules={[DEFAULT_REQUIRED_RULES]}
          shouldUpdate
        >
          {({ value, onChange }) => {
            return (
              <div className={`${classes.d_flex_column} ${classes.w_50}`}>
                <SelectBoxOffices
                  disabled={disabled}
                  ref={selectOfficesRef}
                  options={officesOptions}
                  onSelect={(officesValue) => {
                    setOfficesSelected(officesValue);
                    onChange(officesValue);
                    resetFileGroupUsers();
                  }}
                  initialValue={form.getFieldValue('eventForOffices')}
                />
                {officesSelected ? (
                  <div className={`${classes.d_flex_center} ${classes.mt_3}`}>
                    {officesSelected.map((item: any, index: number) => (
                      <Chip
                        key={index}
                        disabled={disabled}
                        className={`${classes.mr_4}`}
                        label={item}
                        onDelete={(e: React.ChangeEvent<HTMLInputElement>) => {
                          e.preventDefault();
                          let officesTmp = [...officesSelected];
                          officesTmp.splice(index, 1);

                          setOfficesSelected(officesTmp);
                          onChange(officesTmp);
                          selectOfficesRef.current?.unselect(officesTmp);
                          resetFileGroupUsers();
                        }}
                        deleteIcon={<ClearIcon style={{ color: 'red' }} />}
                        variant="outlined"
                      />
                    ))}
                  </div>
                ) : (
                  ''
                )}
              </div>
            );
          }}
        </Form.Item>

        <Form.Item
          childStyle={{ marginTop: 16, alignItems: 'flex-start' }}
          name="typeOfViews"
          label={Translation('component.formLabel.type-of-view')}
          rules={TYPE_OF_VIEW_RULES}
          shouldUpdate
        >
          {({ value, onChange }) => {
            const onChangeFileUploader = (item: any) => {
              SetUserFile(item ? item : undefined);

              const index = value.findIndex((x: any) => x.type === EViewType.GroupOfUsers);
              if (index !== -1) {
                value[index].userFile = item;
                if (!onChange) return;
                onChange([...value]);
              }
            };
            return (
              <div>
                <span>Group of user</span>
                <div className={`${classes.mt_10} ${classes.mb_10}`}>
                  <FileUploader
                    disabled={disabled}
                    upload={handleUploadFile}
                    color="inherit"
                    showAllowText={Translation('resouce.common.type-of-view-description')}
                    maxFileSize={10}
                    allowedFileTypes={['csv', 'xlsx']}
                    btnTxt={Translation('app.button.upload').toUpperCase()}
                    value={userFile}
                    onChange={onChangeFileUploader}
                  />
                </div>

                <a className={`${classes.downloadTxt}`} href={toAbsoluteUrl('/templates/Template_Group_Of_User.csv')}>
                  {Translation('resource.form.downloadTemplate')}
                </a>
              </div>
            );
          }}
        </Form.Item>

        <Form.List name="moduleColumn">
          {(fields: any, { add, remove }: any) => {
            return fields.map((field: any, index: any) => (
              <Form.Item
                childStyle={{ marginTop: 16, height: 32 }}
                label={Translation('component.formLabel.operation-column')}
                rules={[DEFAULT_REQUIRED_RULES]}
                name={[field.name, 'column']}
                style={{ width: 'auto', flexGrow: 1 }}
              >
                <TextField
                  SelectProps={{
                    MenuProps: {
                      style: {
                        maxHeight: 400,
                      },
                    },
                  }}
                  disabled={disabled}
                  select
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  placeholder={''}
                >
                  {columnOptions.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </Form.Item>
            ));
          }}
        </Form.List>

        <Form.Item
          childStyle={{ marginTop: 16 }}
          rules={DATE_VIDATOR}
          name="effectiveRange"
          label={Translation('component.formLabel.effective-date')}
          dependencies={['effectiveRange', 'eventDate']}
        >
          {({ ...fProps }) => {
            const validRange = getValidRange(form, 'effectiveRange');

            return (
              <DateTimeRangePicker
                minToDate={validRange.minToDate}
                maxFromDate={validRange.maxFromDate}
                {...fProps}
                disabled={disabled}
              />
            );
          }}
        </Form.Item>

        <Form.Item
          childStyle={{ marginTop: 4 }}
          name="eventDate"
          label={Translation('component.formLabel.event-date')}
          rules={EVENT_DATE_VIDATOR}
          dependencies={['effectiveRange', 'eventDate']}
        >
          {({ ...fProps }) => {
            const validRange = getValidRange(form, 'eventDate');

            return (
              <DateTimeRangePicker
                minFromDate={validRange.minFromDate}
                maxToDate={validRange.maxToDate}
                maxFromDate={validRange.maxFromDate}
                {...fProps}
                disabled={disabled}
              />
            );
          }}
        </Form.Item>

        <Form.List name="agendaList">
          {(fields: any, { add, remove }: any) => {
            return fields.map((field: any, index: any) => (
              <div key={field.key} style={{ display: 'flex', alignContent: 'start', width: '70%' }}>
                <Form.Item
                  name={[field.name, 'time']}
                  label={index === 0 ? agendaListLabel : ' '}
                  required={false}
                  rules={getTimeAgendaRules(index)}
                  style={{ width: '50%', flexGrow: 1 }}
                >
                  {({ value, onChange }) => {
                    return (
                      <TimePicker
                        ampm={false}
                        disabled={disabled}
                        value={value ? dayjs(value) : null}
                        onChange={onChange}
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            margin: 'dense',
                            placeholder: 'Time',
                          },
                        }}
                      />
                    );
                  }}
                </Form.Item>
                <Form.Item
                  name={[field.name, 'content']}
                  required={false}
                  rules={getContentAgendaRules(index)}
                  style={{ width: '50%', marginLeft: '11px', flexGrow: 1 }}
                >
                  <TextField
                    type="text"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    disabled={disabled}
                    placeholder={Translation('component.formLabel.agendaList.content')}
                    inputProps={{ maxLength: 100 }}
                  />
                </Form.Item>
                {!disabled ? (
                  <div
                    style={{
                      display: 'flex',
                      width: '70px',
                      justifyContent: 'space-around',
                      marginTop: '11.5px',
                      marginLeft: '10px',
                    }}
                  >
                    {fields.length < 6 ? (
                      <AddIcon
                        className={`${classes.cursorPointer}`}
                        fontSize="large"
                        onClick={() =>
                          (() => {
                            // add({ time: null, content: '' });
                            let item = form.getFieldValue('agendaList');
                            item.splice(index + 1, 0, { time: null, content: '' });
                            form.setFieldsValue({ agendaList: item });
                          })()
                        }
                      />
                    ) : null}
                    {fields.length > 1 ? (
                      <DeleteOutlineIcon
                        fontSize="large"
                        className={`${classes.cursorPointer}`}
                        onClick={() => remove(index)}
                      />
                    ) : null}
                  </div>
                ) : null}
              </div>
            ));
          }}
        </Form.List>

        {!disabled ? (
          <Form.Item
            childStyle={{ marginTop: 16, height: 32 }}
            name="status"
            label={Translation('component.formLabel.publish-now')}
            rules={[DEFAULT_REQUIRED_RULES]}
          >
            <RadioGroup aria-label="contentType" name="contentType" style={{ display: 'flex', flexDirection: 'row' }}>
              {publishItems &&
                publishItems.map((item: any) => {
                  return (
                    item && (
                      <FormControlLabel
                        key={item.key}
                        value={item.key}
                        control={<Radio disabled={disabled} />}
                        label={Translation(item.label)}
                      />
                    )
                  );
                })}
            </RadioGroup>
          </Form.Item>
        ) : null}

        <div className={classes.footerContainer}>
          {!disabled ? (
            <>
              <ButtonDialog
                btnClasses={`${classes.button} ${classes.bg_gray}`}
                onOk={onCancel}
                dialogTxt={Translation('component.hint.cancel-warning')}
                btnTxt={Translation('app.button.cancel').toUpperCase()}
              />
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.isPublish !== currentValues.isPublish}
              >
                {(_, meta, { getFieldValue }) => {
                  const handleSubmit = () => {
                    onSubmit(getFieldValue);
                  };

                  const debounceSubmit = debounce(handleSubmit, 500);
                  return (
                    <Button
                      className={`${classes.button} ${classes.highlightBtn}`}
                      variant="contained"
                      onClick={debounceSubmit}
                    >
                      {Translation('app.button.submit').toUpperCase()}
                    </Button>
                  );
                }}
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.isPublish !== currentValues.isPublish}
              >
                <Button
                  className={`${classes.button} ${classes.bg_gray}`}
                  variant="contained"
                  onClick={onNavigateFeedback}
                >
                  {Translation('app.button.feedback').toUpperCase()}
                </Button>
              </Form.Item>
            </>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PublishSettings;
