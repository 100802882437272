import React, { FC, useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ModulePermissionProps } from '../../../../../../common/module/types';
import PruDialog from '../../../../../../common/components/PruDialog';
import PruFilter, { PruFilterItemType } from './components/Filter';

import { useDataProvider } from 'src/app/common/utils/hook-utils';
import LibraryList from './components/LibraryList';
import { forEach } from 'lodash';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { useLang } from 'src/app/i18n';
import jwt_decode from 'jwt-decode';
import { LibraryPublishStatus } from '../../constants';
import { QRCodeCanvas } from 'qrcode.react';
import { ColumnPaginateList, LibraryItem, LibraryListParam, LibraryPaginateList } from '../../types';

import {
  editUnpublishLibrary,
  fetchAllColumnList,
  fetchLibraryList,
  modifyLibrary,
} from '@/app/modules/ResourceLibrary/network/resourceCrud';
import { RootState } from '@/redux/store';

type ResourceProps = {
  filterName: string;
  blockName: string;
  blockType: string;
  createPagePath: string;
  editPageBasePath: string;
  viewPageBasePath: string;
  moduleType: string;
  performancePagePath?: string;
} & RouteComponentProps &
  ModulePermissionProps;

const paramsInitiator = (moduleType: string, officeCodes: string[]): LibraryListParam => {
  return {
    page: 1,
    pageSize: 20,
    name: '',
    module: moduleType,
    officeCodes,
  };
};

const SalesLibraryListingPage: FC<ResourceProps> = ({
  history,
  filterName,
  moduleType,
  blockName,
  createPagePath,
  editPageBasePath,
  viewPageBasePath,
  performancePagePath,
  enableCreate,
  enableUpdate,
}) => {
  const locale = useLang();
  const dispatch = useDispatch();
  const intl = useIntl();
  const qrRef = useRef<any>();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { officeCodes } = useSelector<RootState, any>((state) => {
    return {
      officeCodes: state.auth.officeCodes,
    };
  }, shallowEqual);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [filterState, setFilterState] = useState<LibraryListParam>(paramsInitiator(moduleType, officeCodes));
  const [libraryList, setLibraryList] = useState<LibraryPaginateList>();
  const [columnOptionList, setColumnOptionList] = useState<{ displayName: string; value: string }[]>();
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);
  const [selectedUnpublishRow, setSelectedUnpublishRow] = useState<LibraryItem>();
  const [qrCodeId, setQrCodeId] = useState('');

  const filterOptions = {
    status: [
      { displayName: Translation('component.status.all'), value: '' },
      { displayName: Translation('component.status.published'), value: 'Published' },
      { displayName: Translation('component.status.unpublished'), value: 'Unpublished' },
      { displayName: Translation('component.status.draft'), value: 'Draft' },
    ],
  };

  const { isLoading, refreshData } = useDataProvider<LibraryPaginateList>(
    async () => {
      try {
        if (!officeCodes) {
          return;
        }

        const payload = {
          ...filterState,
          officeCodes,
          name: trimString(filterState.name || ''),
        };
        return await fetchLibraryList(locale, payload, sortKey, dispatch);
      } catch (err) {}
    },
    setLibraryList,
    false,
  );

  useDataProvider<ColumnPaginateList>(
    async () => {
      try {
        const allColumnList = await fetchAllColumnList(moduleType, dispatch);
        let optionList: { displayName: string; value: string }[] = [
          { displayName: Translation('component.status.all'), value: '' },
        ];
        allColumnList.columnList.map((item) => {
          //@ts-ignore
          return optionList.push({ displayName: item.name[locale] ? item.name[locale] : '', value: item.id });
        });
        setColumnOptionList(optionList);
        return allColumnList;
      } catch (err) {}
    },
    () => {},
    false,
  );

  useEffect(() => {
    if (libraryList) {
      refreshData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortKey]);

  const trimString = (name: string) => {
    let str = name.trim();
    let strReserved = reverseString(str).trim();
    return reverseString(strReserved);
  };

  const reverseString = (str: string) => {
    return str.split('').reverse().join('');
  };

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };

  const closeWaringDialog = () => {
    setOpenDialog(false);
  };

  const onUnpublish = () => {
    closeWaringDialog();
    if (selectedUnpublishRow) {
      handleUnpublishLibrary(selectedUnpublishRow);
    }
  };

  const onUnpublishButtonClick = (rowData: LibraryItem) => {
    if (
      rowData.parentItems &&
      (rowData.parentItems as { count: number }).count &&
      (rowData.parentItems as { count: number }).count > 0
    ) {
      setOpenDialog(true);
      setSelectedUnpublishRow(rowData);
    } else {
      handleUnpublishLibrary(rowData);
    }
  };

  const openAddPage = (url: string, type?: string, selectedRows?: any[]) => {
    history.push({
      pathname: url,
      state: {
        type,
        selectedRows,
      },
    });
  };

  const openEditPage = (rowData: LibraryItem, mode: string) => {
    if (mode === 'view') {
      history.push({
        pathname: `${viewPageBasePath}/${rowData.id}`,
        state: {},
      });
    } else {
      history.push({
        pathname: `${editPageBasePath}/${rowData.id}`,
        state: {},
      });
    }
  };

  const openPerformancePage = (rowData: LibraryItem) => {
    if (performancePagePath)
      history.push({
        pathname: `${performancePagePath}/${rowData.uuid}`,
        state: { name: rowData.name },
      });
  };

  const handleUnpublishLibrary = async (rowData: LibraryItem) => {
    const isInMaterialSet =
      rowData.parentItems &&
      (rowData.parentItems as { count: number }).count &&
      (rowData.parentItems as { count: number }).count > 0;

    try {
      let jwtDetail: any;
      if (localStorage.getItem('jwt')) {
        jwtDetail = jwt_decode(localStorage.jwt);
      }
      let submitData: any = {
        updateBy: jwtDetail.username || '',
        status: LibraryPublishStatus.Unpublished,
      };

      if (isInMaterialSet) {
        submitData.parentItems = [];
      }
      await modifyLibrary(rowData.id, submitData);
      await editUnpublishLibrary(rowData.id, submitData);
      forEach(rowData.localizations, async (localeItem, key) => {
        await modifyLibrary(localeItem.id, submitData);
        await editUnpublishLibrary(localeItem.id, submitData);
      });
    } catch (err) {}

    try {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Unpublish successfully `,
          },
        ]),
      );
      refreshData();
    } catch (err) {}
  };

  const onDownloadQR = (rowData: LibraryItem) => {
    setQrCodeId(
      `${rowData.checkInUrl}?id=${rowData.id}&eventStartDate=${rowData.eventStartDate}&eventEndDate=${rowData.eventEndDate}&eventForAd=true` ||
        '',
    );

    setTimeout(() => {
      const canvas = qrRef.current.querySelector('canvas');
      const image = canvas.toDataURL('image/png');
      const anchor = document.createElement('a');
      anchor.href = image;
      anchor.download = `qr-invitation-${rowData.id}.png`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      setQrCodeId('');
    }, 500);
  };

  const qrcode = React.useMemo(() => {
    return <QRCodeCanvas id="qrCode" value={qrCodeId} size={256} />;
  }, [qrCodeId]);

  return (
    <>
      <PruFilter
        title={Translation(filterName)}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            style: { width: '100%' },
            initialValue: filterState.name,
            displayName: Translation('component.formLabel.name'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: '100%' },
            field: 'status',
            initialValue: filterState.status,
            displayName: Translation('component.formLabel.status'),
            choices: filterOptions.status,
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: '100%' },
            field: 'column',
            initialValue: filterState.column,
            displayName: Translation('component.formLabel.published-to-module'),
            choices: columnOptionList ? columnOptionList : [{ displayName: 'All', value: '' }],
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            style: { width: '100%' },
            fieldFrom: 'createTimeStart',
            fieldTo: 'createTimeEnd',
            initialDateFrom: null,
            initialDateTo: null,
            displayName: Translation('component.formLabel.created-time'),
          },
        ]}
        onChangeFilter={(data) => {
          setFilterState({
            ...filterState,
            name: data.name,
            status: data.status,
            column: data.column,
            createTimeStart: data.createTimeStart,
            createTimeEnd: data.createTimeEnd,
          });
        }}
        fetchData={refreshData}
      />
      <LibraryList
        enablePerformance={!!performancePagePath}
        isLoading={isLoading}
        libraryList={libraryList}
        libraryTableTitle={blockName}
        moduleType={moduleType}
        onRefresh={refreshData}
        onSort={(dataKey) => updateSortingKey(dataKey)}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            pageSize: rowsPerPage,
          });
          refreshData();
        }}
        onAddLibrary={(type, rowData) => {
          openAddPage(createPagePath, type, rowData);
        }}
        onEditLibrary={(rowData, mode) => {
          openEditPage(rowData, mode);
        }}
        onPerformance={(rowData) => {
          openPerformancePage(rowData);
        }}
        onUnpublishLibraryClicked={(rowData) => {
          onUnpublishButtonClick(rowData);
        }}
        onDownloadQR={(rowData) => {
          onDownloadQR(rowData);
        }}
        enableUpdate={enableUpdate}
        enableCreate={enableCreate}
        currentLanguage={locale}
      />
      <PruDialog
        open={openDialog}
        canCloseDialog={true}
        onCancel={closeWaringDialog}
        onOk={onUnpublish}
        confirmBtnText={Translation('global.text.confirm')}
        canncelBtnText={Translation('app.button.cancel')}
      >
        {Translation('warning.message.unpublish.material')}
      </PruDialog>

      <div ref={qrRef} style={{ display: 'none' }}>
        {qrcode}
      </div>
    </>
  );
};

export default SalesLibraryListingPage;
